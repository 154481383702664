var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-center justify-center py-6"},[_vm._v(" Hier kannst du deinen persönlichen Account anpassen. ")]),_c('v-card-text',[_c('v-container',[_c('v-subheader',{staticClass:"ma-5"},[_vm._v(" Hier musst du deine persönlichen Daten angeben. Deine Stammes-Zugehörigkeit sowie deinen Fahrtenname sind wichtig, damit du dich oder deinen Stamm bei Fahrten anmelden kannst. Fülle die Felder deswegen unbedingt aus. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Mein (Fahrten)-Name*","prepend-icon":"mdi-account-circle","error-messages":_vm.scoutNameErrors},on:{"change":_vm.updateData},model:{value:(_vm.scoutName),callback:function ($$v) {_vm.scoutName=$$v},expression:"scoutName"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip.scoutName)+" ")])])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"readonly":"","disabled":"","filled":"","label":"Meine E-Mail Adresse*","prepend-icon":"mdi-email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip.email)+" ")])])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"readonly":"","filled":"","label":"Mein Stamm*","prepend-icon":"mdi-account-group","error-messages":_vm.stammErrors},model:{value:(_vm.getStammName),callback:function ($$v) {_vm.getStammName=$$v},expression:"getStammName"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onPickStammClick}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip.scoutOrganisation)+" ")])])],1)],2),_c('pick-stamm-form',{ref:"pickStamm",on:{"sendIdToParent":_vm.tranferId}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Meine Handynummer*","prepend-icon":"mdi-cellphone","error-messages":_vm.mobileNumberErrors},on:{"change":_vm.updateData},model:{value:(_vm.mobileNumber),callback:function ($$v) {_vm.mobileNumber=$$v},expression:"mobileNumber"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip.mobileNumber)+" ")])])],1)],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/datenschutz","target":"_blank"}},[_vm._v(" Link zur Datenschutzerklärung ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Ich habe die Datenschutzerklärung gelesen und akzeptiert!","required":"","error-messages":_vm.checkboxErrors},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSaveClicked}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-check")]),_vm._v(" Änderungen speichern ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{attrs:{"dark":"","color":"error"},on:{"click":_vm.onDeleteClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Meine Daten löschen. ")],1)],1)],1)],1)],1),_c('YesNoDialog',{ref:"yesNoDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }