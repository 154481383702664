<template>
  <OverviewMain/>
</template>

<script>
// import DetailMain from './tabs/details/Main.vue';
import OverviewMain from './tabs/overview/Main.vue';

export default {
  components: {
    OverviewMain,
    // DetailMain,
  },
};
</script>
