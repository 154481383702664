var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center"},[_c('v-card',{staticClass:"mx-auto pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"center text-center justify-center pa-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"clearable":"","loading":_vm.loading,"items":_vm.availableFileTemplates,"label":"Filter nach Typ","multiple":"","item-text":"type","item-value":"id","no-data-text":"Keine Datei Typen gefunden."},on:{"change":_vm.onFilterSelected},model:{value:(_vm.selectedFileTemplateFilter),callback:function ($$v) {_vm.selectedFileTemplateFilter=$$v},expression:"selectedFileTemplateFilter"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"clearable":"","loading":_vm.loading,"items":_vm.availableStatus,"label":"Filter nach Status","multiple":"","item-text":"name","item-value":"value"},on:{"change":_vm.onFilterSelected},model:{value:(_vm.selectedStatusFilter),callback:function ($$v) {_vm.selectedStatusFilter=$$v},expression:"selectedStatusFilter"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.onNewClicked()}}},[_vm._v(" Neue Datei anfordern ")])],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"overflow-y: auto",attrs:{"justify":"center"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getItems,"items-per-page":_vm.itemsPerPage,"expanded":_vm.expanded,"show-expand":"","single-expand":"","hide-default-footer":"","item-key":"createdAt","loading":_vm.loading,"no-data-text":"Keine Downloads verfügbar."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserText(item.user))+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.file,"color":"primary","small":"","icon":"","dark":""},on:{"click":function($event){return _vm.onDownloadClicked(item.file)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v(" Fehlermeldung: "),_c('pre',[_vm._v(" "+_vm._s(item.errorMsg)+" ")])])]}}],null,true)})],1),_c('GenerateFileRequestModal',{ref:"requestFileModal",on:{"createFileRequest":_vm.createFileRequest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }