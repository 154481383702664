var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center"},[_c('v-card',{staticClass:"mx-auto pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('RegistrationFilter',{attrs:{"eventId":_vm.eventId,"preSelectStamm":"true"},on:{"onFilterSelected":_vm.onFilterSelected}})],1)],1)],1)],1),(_vm.data)?_c('v-row',{staticClass:"overflow-y: auto",attrs:{"justify":"center"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.getPersonsList,"must-sort":"","expanded":_vm.expanded,"headers":_vm.headers,"footer-props":{
          itemsPerPageText: 'Personen pro Seite',
          'items-per-page-options': [10, 20, 30, 40, 50, -1]
        },"show-expand":"","single-expand":"","options":_vm.options,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsPerPage,"sort-by":"lastName","item-key":"createdAt","no-data-text":"Keine Teilnehmer Gefunden.","loading-text":"Lade Teilnehmer..."},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.birthday",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBirthday(item))+" ")]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [(item.street && item.zipCode)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Adresse: ")]),[_vm._v(" "+_vm._s(((item.street) + ", " + (item.zipCode.zipCode) + " " + (item.zipCode.city)))+" ")]],2)],1):_vm._e(),(item.phoneNumber)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Tel:")]),[_vm._v(" "+_vm._s(item.phoneNumber)+" ")]],2)],1):_vm._e(),(item.eatHabit)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Essen: ")]),_vm._v(" "+_vm._s(_vm.getEatHabits(item.eatHabit))+" ")])],1):_vm._e(),(item.leader)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Amt: ")]),_vm._v(" "+_vm._s(item.leader)+" ")])],1):_vm._e(),(item.gender)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Geschlecht: ")]),_vm._v(" "+_vm._s(item.gender)+" ")])],1):_vm._e(),(item.bookingOption)?_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Option: ")]),_vm._v(" "+_vm._s(item.bookingOption.name)+" ")])],1):_vm._e()]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" von "+_vm._s(items.itemsLength)+" Personen ")]}}],null,true)})],1):_c('v-row',{attrs:{"justify":"center"}},[_c('p',[_vm._v("Bitte wähle einen Stamm")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }