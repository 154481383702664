var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center"},[_c('v-card',{staticClass:"mx-auto pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('RegistrationFilter',{attrs:{"eventId":_vm.eventId},on:{"onFilterSelected":_vm.onFilterSelected}})],1)],1)],1)],1),_c('v-row',{staticClass:"overflow-y: auto",attrs:{"justify":"center"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.data,"expanded":_vm.expanded,"show-expand":"","single-expand":"","item-key":"createdAt","options":_vm.options,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsPerPage,"must-sort":"","sort-by":"createdAt","footer-props":{
          itemsPerPageText: 'Personen pro Seite',
          'items-per-page-options': [10, 20, 30, 40, 50, -1]
        },"no-data-text":"Keine Anmeldungen Gefunden.","loading-text":"Lade Anmeldungen..."},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.isConfirmed",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isConfirmed ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isConfirmed ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.single",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.single ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.single ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.numberParticipant",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"disabled":""},domProps:{"innerHTML":_vm._s(_vm.getNumberParticipant(item))}})]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Verantwortlich(e): ")]),_vm._v(" "+_vm._s(_vm.getResponsiblePersonsersons(item))+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Kontakt(e): ")]),_vm._v(" "+_vm._s(item.responsiblePersonsExtended)+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_c('b',[_vm._v("Buchungsoption: ")]),_vm._l((item.bookingOptions),function(item,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(item.bookingOptions)+": "+_vm._s(item.sum)+" ")])})],2)],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v("Summe")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Registrierungen: "+_vm._s(_vm.getTotalRegistrations))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" Teilnehmer: "+_vm._s(_vm.getTotalPariticipants))])])]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" von "+_vm._s(items.itemsLength)+" Anmeldungen ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }