var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center"},[_c('v-card',{staticClass:"mx-auto pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center pa-0"},[_c('RegistrationFilter',{attrs:{"eventId":_vm.eventId},on:{"onFilterSelected":_vm.onFilterSelected}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"overflow-y: auto mb-3",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.data,"single-expand":"","item-key":"createdAt","options":_vm.options,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsPerPage,"must-sort":"","sort-by":"createdAt","footer-props":{itemsPerPageText: 'Anmeldungen pro Seite'},"no-data-text":"Keine Anmeldungen Gefunden.","loading-text":"Lade Anmeldungen..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.isConfirmed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isConfirmed ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isConfirmed ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.single",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.single ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.single ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.numberParticipant",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"disabled":""},domProps:{"innerHTML":_vm._s(_vm.getNumberParticipant(item))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete")]),_c('v-icon',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.onAddResponsablePerson(item)}}},[_vm._v(" mdi-share-variant ")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" von "+_vm._s(items.itemsLength)+" Anmeldungen ")]}}],null,true)})],1)],1),_c('confirm-registration-edit-modal',{ref:"confirmRegistrationEditModal"}),_c('DeleteModal',{ref:"deleteModal"}),_c('DialogAddResponsablePerson',{ref:"dialogAddResponsablePerson"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }