var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"center text-center justify-center"},[_c('v-card',{staticClass:"mx-auto pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"center text-center justify-center pa-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":"Nur Bestätigt","hide-details":""},model:{value:(_vm.filter.justConfirmed),callback:function ($$v) {_vm.$set(_vm.filter, "justConfirmed", $$v)},expression:"filter.justConfirmed"}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.isTeam)?_c('v-btn',{staticClass:"ma-2",on:{"click":_vm.openReminderDialog}},[_c('v-icon',{attrs:{"color":"#008000","left":""}},[_vm._v(" mdi-email-arrow-right")]),_vm._v(" Erinnerungsmail ")],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"overflow-y: auto",attrs:{"justify":"center"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getItems,"items-per-page":_vm.itemsPerPage,"expanded":_vm.expanded,"show-expand":"","single-expand":"","hide-default-footer":"","item-key":"createdAt","loading":_vm.loading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.isConfirmed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isConfirmed ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isConfirmed ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.mailButton",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.onNewClicked(item)}}},[_c('v-icon',[_vm._v(" mdi-cash-100 ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.numberParticipant",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"disabled":""},domProps:{"innerHTML":_vm._s(_vm.getNumberParticipant(item))}})]}},{key:"item.payement.price",fn:function(ref){
var item = ref.item;
return [_c('td',{domProps:{"innerHTML":_vm._s(_vm.getPrice(item.payement.price))}})]}},{key:"item.payement.paid",fn:function(ref){
var item = ref.item;
return [_c('td',{domProps:{"innerHTML":_vm._s(_vm.getPrice(item.payement.paid))}})]}},{key:"item.payement.open",fn:function(ref){
var item = ref.item;
return [_c('td',{class:item.payement.open > 0 ? 'open-position' : 'closed-position',domProps:{"innerHTML":_vm._s(_vm.getPrice(item.payement.open))}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-list-item',[_c('v-list-item-title',{staticClass:"justify-center text-center"},[_vm._v(" Referenz Id: "),_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.refId)+" ")])])])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Verantwortliche Personen(en) ")]),_c('v-list',{attrs:{"dense":"","flat":""}},_vm._l((item.responsiblePersons),function(pers,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(i + 1)+". Person: ")]),_c('v-list-item-subtitle',[_vm._v(" Fahrten Name: "+_vm._s(pers.userextended.scoutName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Name: "+_vm._s(pers.firstName)+" "+_vm._s(pers.lastName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Handy: "+_vm._s(pers.userextended.mobileNumber)+" ")])],1)],1)}),1)],1)],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.onNewClicked(item)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Buchung hinzufügen ")],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{attrs:{"headers":_vm.headersCash,"items":_vm.getItemsCash(item),"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"createdAt","no-data-text":"Keine Buchung vorhanden"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Buchungen")])],1)]},proxy:true},{key:"item.transferDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.transferDate))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPrice(item.amount))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEditClicked(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDeleteClicked(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{attrs:{"headers":_vm.headersBookingOptions,"items":_vm.getItemsBookingOptions(item),"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"createdAt"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Kostenpunkte")]),_c('v-spacer')],1)]},proxy:true}],null,true)})],1)],1)],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{attrs:{"colspan":_vm.tableColSpan}},[_vm._v("Summe")]),_c('th',[_vm._v(_vm._s(_vm.getTotalStamm))]),_c('th',[_vm._v(_vm._s(_vm.getTotalPrice))]),_c('th',[_vm._v(_vm._s(_vm.getTotalPaid))]),_c('th',[_vm._v(_vm._s(_vm.getTotalOpen))])])])],2)],1),_c('TranserCreationModal',{ref:"transerCreationModalRef",on:{"createTransfer":_vm.createTransfer,"editTransfer":_vm.editTransfer}}),_c('TransferDeleteModal',{ref:"transferDeleteModalRef",on:{"refresh":_vm.onRefresh}}),_c('SendPaymentReminderModal',{ref:"sendPaymentReminderModalRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }