var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.selectedItem.length ? '7' : '12'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","single-expand":"","single-select":"","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Suche","dense":"","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.onFilterSelected},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Typ","prepend-inner-icon":"","items":_vm.messageTypes,"dense":"","item-value":"id","multiple":"","item-text":"name"},on:{"change":_vm.onFilterSelected},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?[_c('span',[_vm._v(_vm._s(item.name))])]:_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" + "+_vm._s(_vm.filter.messageTypes.length - 1)+" ")]):_vm._e()]}}]),model:{value:(_vm.filter.messageTypes),callback:function ($$v) {_vm.$set(_vm.filter, "messageTypes", $$v)},expression:"filter.messageTypes"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":"Noch Offen"},on:{"change":_vm.onFilterSelected},model:{value:(_vm.filter.isProcessed),callback:function ($$v) {_vm.$set(_vm.filter, "isProcessed", $$v)},expression:"filter.isProcessed"}})],1)],1)],1)]},proxy:true},{key:"item.isProcessed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isProcessed ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isProcessed ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.messageType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getName(item.messageType))+" ")]}}],null,true),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}),_c('MessageProsessedDialog',{ref:"messageProsessedDialogRef",on:{"refresh":_vm.onFilterSelected}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItem.length),expression:"selectedItem.length"}],staticStyle:{"background":"#f5f5f5"},attrs:{"cols":_vm.selectedItem.length ? '5' : '0'}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-3"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openProceedDialog(_vm.selectedItem[0])}}},[_c('v-icon',[_vm._v(" mdi-tools ")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(_vm.selectedItem[0])}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem = []}}},[_c('v-icon',[_vm._v(" mdi-window-close ")])],1)],1),_c('v-row',{staticClass:"ma-1"},[_c('v-text-field',{attrs:{"label":"Typ","readonly":""},model:{value:(_vm.item.messageType),callback:function ($$v) {_vm.$set(_vm.item, "messageType", $$v)},expression:"item.messageType"}})],1),_c('v-row',{staticClass:"ma-1"},[_c('v-textarea',{attrs:{"label":"Nachricht","readonly":""},model:{value:(_vm.item.messageBody),callback:function ($$v) {_vm.$set(_vm.item, "messageBody", $$v)},expression:"item.messageBody"}})],1),_c('v-row',{staticClass:"ma-3"},[_c('v-textarea',{attrs:{"label":"Interner Vermerk","readonly":""},model:{value:(_vm.item.internalComment),callback:function ($$v) {_vm.$set(_vm.item, "internalComment", $$v)},expression:"item.internalComment"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }