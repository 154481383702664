var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericRegModul',{key:("module-" + _vm.moduleId),attrs:{"loading":_vm.loading,"saving":_vm.saving,"position":_vm.position,"maxPos":_vm.maxPos,"currentMod":_vm.currentModule},on:{"prevStep":_vm.prevStep,"nextStep":_vm.onNextStep,"ignore":_vm.onIngoredClicked,"saving":_vm.onSaving},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("Bitte trage den Modultext ein.")])]},proxy:true},{key:"main",fn:function(){return [_c('v-row',_vm._l((_vm.moduleData),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('BaseField',{key:index,attrs:{"field":{
            name: item.title,
            techName: item.attribute.id,
            tooltip: item.tooltip,
            icon: item.icon,
            mandatory: true,
            fieldType: item.fieldType,
            default: item.defaultValue,
            cols: 12,
          },"valdiationObj":_vm.$v},model:{value:(_vm.data[item.attribute.id]),callback:function ($$v) {_vm.$set(_vm.data, item.attribute.id, $$v)},expression:"data[item.attribute.id]"}})],1)}),1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }